<template>
  <div class="auth">
    <Header title="Авторизация" isAuth />

    <div class="wrapper">
      <div class="wrapper__inner">
        <img src="@/assets/img/logo.png" alt="" class="logo">
        <ul class="progress-bar">
          <li
            class="progress-bar__item"
            :class="{ 'progress-bar__item--active': dot.active, 'progress-bar__item--error': pinError }"
            v-for="(dot, dotIndex) in dots"
            :key="dotIndex"
          ></li>
        </ul>
        <ul class="input-box">
          <li
            class="input-box__item"
            :class="{ 'input-box__item--active': active === key.id }"
            v-for="(key, keyIndex) in keys"
            :key="keyIndex"
            @click="userInput(keyIndex, key.value)"
          >
            <div
              class="input-box__item-inner"
              v-if="key.value"
            >
              {{ key.value }}
            </div>
            <img
              :src="require(`@/assets/img/${key.img}`)"
              alt=""
              v-else
            >  
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Auth',
  components: {
    Header
  },
  data () {
    return {
      active: null,
      pin: '',
      keys: [
        {
          id: 0,
          value: '1',
        },
        {
          id: 1,
          value: '2',
        },
        {
          id: 2,
          value: '3',
        },
        {
          id: 3,
          value: '4',
        },
        {
          id: 4,
          value: '5',
        },
        {
          id: 5,
          value: '6',
        },
        {
          id: 6,
          value: '7',
        },
        {
          id: 7,
          value: '8',
        },
        {
          id: 8,
          value: '9',
        },
        {
          id: 9,
          img: 'delete.png'
        },
        {
          id: 10,
          value: '0',
        },
        {
          id: 11,
          img: 'arrow.png'
        }
      ],
      dots: [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false }
      ]
    }
  },
  beforeCreate () {
    localStorage.removeItem('pin')
    localStorage.removeItem('sessionId')
  },
  computed: {
    pinError () {
      return this.$store.state.auth_error ? this.$store.state.auth_error : false
    }
  },
  methods: {
    userInput (index, value) {
      // HIGHLIGHT
      setTimeout(() => {
        this.active = null
      }, 500)
      if (index === this.active) {
        this.active = null
        this.$nextTick(() => {
          this.active = index
        })        
      } else {
       this.active = index
      }

      // PIN INPUT
      if (index != 9 && index != 11 && this.pin.length < 6) {
        this.pin += value
        this.dots[this.pin.length - 1].active = true
      }
      // PIN INPUT VALIDATE
      if (index != 9 && index != 11 && this.pin.length === 6) {
        this.$store.dispatch('CHECK_AUTH', this.pin)
          .then((response) => {
            if (response.status === 200) {
              if (this.$store.state.isAdmin) {
                this.$router.push('/couriers')
              } else {
                this.$router.push('/orders')
              }
            } else {
              this.pin = ''
              this.dots.map((dot) => {
                dot.active = false
              })
              throw new Error(response)
            }             
          })
          .catch((error) => {
            this.pin = ''
            this.dots.map((dot) => {
              dot.active = false
            })
            // eslint-disable-next-line
            console.error(error)
          })
      }

      // DELETE
      if (index === 9) {
        this.pin = ''
        this.dots.map((dot) => {
          dot.active = false
        })
      } 

      // BACKSPACE
      if (index === 11) {
        this.pin = this.pin.slice(0, -1)
        this.dots[this.pin.length].active = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth {
  min-height: 100vh;
  background-color: #f2f2f2;
}
.wrapper {
  width: 220px;
  margin: 10px auto 55px;
  display: flex;
  align-items: center;
  min-height: calc(100vh - 50px - 65px);
}
.logo {
  margin: 0 auto 30px;
}
.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  &__item {
    width: 20px;
    height: 20px;
    background-color: #ababab;
    position: relative;
    border-radius: 50px;
    &::after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: 3px;
      left: 3px;
      background-color: #f2f2f2;
      border-radius: 50px;
    }      
    &--active {
      background-color: $red;
      &::after {
        display: none;
      }
    }
    &--error {
      animation: bounce 0.5s;
    }
  }
}
.input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  &__item {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9px;
    cursor: pointer;
    color: #000; 
    user-select: none;
    &-inner {      
      font-size: 24px;
      line-height: 1;
      position: relative;
      top: -1px;
      left: -1px;
    }
    &--active {
      animation: highlight 0.5s ease-out;
    }
  }
}

@keyframes highlight {
  0% {
    background-color: $main;
  }
  100% {
    background-color: #e7e7e7;
  }
}
@keyframes bounce {
  0% {
    left: 0px;
  }
  20% {
    left: 25px;
  }
  40% {
    left: -16px;
  }
  60% {
    left: 12px;
  }
  80% {
    left: -8px;
  }
  90% {
    left: 4px;
  }
  100% {
    left: 0px;
  }
}
</style>
